<template>
  <div>
    <header>
      <div
        style="
          text-align: center;
          color: var(--main);
          line-height: 28px;
          padding: 40px 40px 20px 40px;
        "
      >
        <h1 style="font-size: 25px; font-weight: 800">Goals</h1>
        <p style="font-size: 12px; font-weight: 600">
          Complete your goals or remove them
        </p>
      </div>
    </header>

    <div v-if="getGoals.length > 0" style="padding: 0 10px">
      <AppGoalItemNewMain
        v-for="goal in getGoals"
        :key="goal.id"
        :goal="goal"
        :progress="progressRadius"
        @triggerGoalItem="showGoalDetails(goal)"
        @deleteGoalItem="deleteItem(goal.id)"
        @addGoalFunds="
          openSheetTransferFunds(
            goal.id,
            goal.accounts_id,
            goal.cash_u_need_more,
            goal.product.price,
          )
        "
      />
    </div>
    <div v-else class="recent_container" style="padding: 0 20px">
      <div
        class="shadow"
        style="
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 90px;
          margin-bottom: 8px;
          display: flex;
          align-items: center;
          background: white;
          padding: 15px;
          border-radius: 15px;
          position: relative;
          overflow: hidden;
        "
      >
        <div
          style="
            text-align: center;
            color: var(--secondary);
            font-size: 12px;
            font-weight: 500;
          "
        >
          No items found
        </div>
      </div>
    </div>

    <Sheet :show.sync="sheetTransferFunds" :on-close="closeSheetTransferFunds">
      <div v-if="currentstep == 1">
        <h1 class="transferInfoTitle">Transfer money from:</h1>

        <div class="appTransferFunds__wallets" v-if="user.accounts">
          <div
            class="walletScroll recent_container"
            style="scroll-padding: 1.5rem; margin-top: -10px"
          >
            <TransferWallets
              v-for="(wallet, index) in user.accounts.filter(
                (item) =>
                  (item.name == 'main' || item.name == 'close') &&
                  item.current_balance > 0
              )"
              :key="index"
              :wallet="wallet"
              @click.native="selectTransfer(wallet.id, wallet, 'wallet')"
              :style="[
                selectedTransferAccount == wallet.id
                  ? {}
                  : {
                      background: '#ccc',
                      filter: 'grayscale(100%)',
                    },
              ]"
              class="transferWallet walletScroll__item"
              style="margin-right: 10px; min-height: 130px"
            />

            <div
              class="goal-item walletScroll__item shadow"
              style="
                flex-direction: column;
                align-items: flex-start;
                padding: 15px;
                border-radius: 13px;
                margin-right: 10px;
                min-height: 130px;
              "
              v-for="goal in getGoals.filter(
                (item) =>
                  item.id !== selectedGoal.goal_id &&
                  item.account.current_balance > 0
              )"
              :key="goal.id"
              @click="
                goal.account.current_balance == 0
                  ? null
                  : selectTransfer(goal.account.id, goal, 'goal')
              "
              :style="[
                selectedTransferAccount == goal.account.id
                  ? {}
                  : {
                      background: '#ccc',
                      filter: 'grayscale(100%)',
                    },
              ]"
              :class="{
                noFundsGoal: goal.account.current_balance == 0,
              }"
            >
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  width: 100%;
                "
              >
                <div
                  class="goal-img"
                  style="
                    width: 65px;
                    height: 65px;
                    min-width: 65px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <img
                    style="width: 100%"
                    :src="resolveImage(goal.product.default_picture_link)"
                    alt=""
                  />
                </div>
                <div
                  style="
                    font-size: 30px;
                    font-weight: 700;
                    margin-right: 25px;
                    line-height: 21px;
                  "
                >
                  <span class="poundSign">&pound;</span
                  >{{ formatPrice(goal.product.price) }}

                  <div style="font-size: 16px; font-weight: 600">
                    <small
                      >&pound;{{
                        formatPrice(goal.account.current_balance)
                      }}
                      Available</small
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="currentstep == 2" class="addMissionExplChild">
        <h1
          class="transferInfoTitle"
          style="font-size: 16px; margin-bottom: 10px; font-weight: 600"
        >
          How much would you like to transfer?
        </h1>
        <div
          v-if="
            getSpendWalletBalance == 0 &&
            getGoals.filter((item) => item.account.current_balance > 0)
              .length == 0
          "
          class="noFundsError"
        >
          You do not have enough funds to transfer!
        </div>
        <div v-else>
          <div class="amountToTransfer">
            <div style="font-size: 12px">
              You have
              <span>&pound;{{ formatPrice(transferAll) }}</span>
              available for transfer
            </div>

            <div
              v-if="
                getSpendWalletBalance >= selectedGoal.cash_needed ||
                getSaveWalletBalance >= selectedGoal.cash_needed
              "
              class="transferAllAction"
              @click="transferAllSum(selectedGoal.cash_needed)"
            >
              I want to transfer &pound;{{
                formatPrice(selectedGoal.cash_needed)
              }}
            </div>
            <div
              v-else
              class="transferAllAction"
              @click="transferAllSum(transferAll)"
            >
              I want to transfer all
            </div>
          </div>
          <AppErrorMessage :message="morefundsNeeded" />
          <input
            class="guardian-login-form__input"
            placeholder="0.00"
            type="email"
            spellcheck="false"
            v-model="transferObj.sum"
            style="width: 100%; margin-bottom: 15px"
          />

          <AppErrorMessage :message="error" style="display: none" />

          <AppButton
            v-if="transferType == 'goal'"
            text="Transfer"
            :color="validateFunds"
            :mini="true"
            @click="transferAction"
            :disabled="validateFunds != 'blue'"
          />

          <AppButton
            v-if="transferType == 'wallet' && walletType == 'main'"
            text="Transfer"
            :color="validateFunds"
            :mini="true"
            @click="transferActionFromWallet"
            :disabled="validateFunds != 'blue'"
          />

          <AppButton
            v-if="transferType == 'wallet' && walletType == 'close'"
            text="Transfer"
            :color="validateFunds"
            :mini="true"
            @click="transferActionFromCloseWallet"
            :disabled="validateFunds != 'blue'"
          />
        </div>
      </div>
      <div v-if="currentstep == 3" class="addMissionExplChild">
        <h1 class="transferInfoTitleSuccess">Success!</h1>
        <p class="successDesc">
          You've transferred
          <span class="redSuccess"
            >&pound;{{ formatPrice(transferObj.sum) }}</span
          >
          from
          <span class="redSuccess" v-if="finishTransferFrom.name == 'close'"
            >Save Wallet</span
          >
          <span class="redSuccess" v-else-if="finishTransferFrom.name == 'main'"
            >Spend Wallet</span
          >
          <span class="redSuccess" v-else>{{
            finishTransferFrom.product.name.substring(0, 8) + ".."
          }}</span>
          to
          <span class="redSuccess" v-if="finishTransferTo.name == 'close'"
            >Save Wallet</span
          >
          <span class="redSuccess" v-else-if="finishTransferTo.name == 'main'"
            >Spend Wallet</span
          >
          <span class="redSuccess" v-else>{{
            finishTransferTo.product.name.substring(0, 8) + ".."
          }}</span>
        </p>
      </div>

      <div v-if="currentstep <= 1">
        <StepControls
          v-for="(step, index) in steps"
          :step="step"
          :key="index"
          :calendar="checkPassStep"
          :stepcount="steps.length"
          :currentstep="currentstep"
          @step-change="stepChanged"
          @step-submit="transferAction"
        >
        </StepControls>
      </div>
    </Sheet>
  </div>
</template>

<script>
  import AppGoalItemNewMain from "../../components/AppGoalItemNewMain";
  import swal from "sweetalert";
  import Sheet from "../../components/Sheet";
  import { mapActions, mapGetters } from "vuex";
  import status from "../../utils/statusCode";
  import config from "../../config";
  import axios from "axios";
  import TransferWallets from "../../components/TransferWallets";
  import StepControls from "../../components/wizard/StepControls";
  import AppButton from "../../components/AppButton";
  import AppErrorMessage from "../../components/AppErrorMessage";

  (function (doc, win) {
    let docEl = doc.documentElement,
      resizeEvt =
        "orientationchange" in window ? "orientationchange" : "resize",
      recalc = function () {
        let clientWidth = docEl.clientWidth;
        if (!clientWidth) return;
        docEl.style.fontSize = 20 * (clientWidth / 375) + "px";
      };
    if (!doc.addEventListener) return;
    win.addEventListener(resizeEvt, recalc, false);
    doc.addEventListener("DOMContentLoaded", recalc, false);
  })(document, window);

  export default {
    components: {
      AppGoalItemNewMain,
      Sheet,
      TransferWallets,
      StepControls,
      AppButton,
      AppErrorMessage,
    },
    data() {
      return {
        walletType: null,
        progressRadius: 309,
        search: null,
        filtered_goals: [],
        goals: [],
        transferModal: false,
        startX: 0,
        endX: 0,
        currentstep: 1,
        selectedGoal: {
          account_id: null,
          cash_needed: 0,
          goal_price: 0,
        },
        sheetTransferFunds: false,
        selectedTransferAccount: null,
        transferAll: null,
        transferType: null,
        passStep: false,
        finishTransferTo: null,
        selectedTransferToAccount: null,
        transferObj: {
          from: null,
          to: null,
          sum: null,
        },
        steps: [
          {
            id: 1,
            title: "STEP 1",
            icon_class: "fa fa-map-marker",
          },
          {
            id: 2,
            title: "STEP 2",
            icon_class: "fa fa-paper-plane",
          },
          {
            id: 3,
            title: "STEP 3",
            icon_class: "fa fa-paper-plane",
          },
        ],
      };
    },
    methods: {
      ...mapActions([
        "fetchGoals",
        "fetchSpendWalletAndGoalsBalance",
        "setupUser",
        "deleteGoal",
        "fetchProfile",
        "TOAST",
      ]),
      transferAllSum(amount) {
        // this.transferObj.sum = this.selectedGoal.cash_needed;
        this.transferObj.sum = amount;
      },
      transferActionFromCloseWallet() {
        const details = {
          close_account_id: this.selectedTransferAccount,
          goal_account_id: this.selectedGoal.account_id,
          sum: this.transferObj.sum,
          name: "Transfer funds from close wallet to goal",
          description: "Transfer funds from close wallet to goal",
        };

        const token = localStorage.getItem("children_token");
        const userId = localStorage.getItem("children_user_id");
        const familyIdChk = localStorage.getItem("children_family_id");
        axios
          .post("/operations/transferFromCloseToGoal", {
            userId: userId,
            token: token,
            family_id_check: familyIdChk,
            close_account_id: details.close_account_id,
            goal_account_id: details.goal_account_id,
            name: details.name,
            sum: details.sum,
            description: details.description,
          })
          .then((response) => {
            const { statusCode } = response.data;
            if (status.success(statusCode)) {
              this.$store.commit("TRANSFER_FOUNDS_FROM_CLOSE_TO_GOAL", details);

              axios
                .get(
                  "/goalsDashboard/children?" +
                    "userId=" +
                    userId +
                    "&token=" +
                    token +
                    "&family_id_check=" +
                    familyIdChk
                )
                .then(
                  function (response) {
                    const { statusCode } = response.data;

                    if (status.success(statusCode)) {
                      this.$store.commit("FETCH_GOALS", response.data);

                      let item = this.getGoals.find(
                        (item) => item.id === this.selectedGoal.goal_id
                      );
                      // console.log("item: ", item);
                      const productObj = {
                        from: "goals",
                        selectedSection: "details",
                        name: item.product.name,
                        productId: item.product.id,
                        description: item.product.description,
                        price: Number(item.product.price).toFixed(2),
                        stars: Number(item.product.stars),
                        defaultPicture: item.product.default_picture_link,
                        images: this.getProductImages(item.product),
                        cashYouMoreNeed:
                          item.cash_u_need_more != null ||
                          item.cash_u_need_more != undefined
                            ? Number(item.cash_u_need_more)
                            : 0,
                        id: item.id,
                        savingsAdded: Number(
                          item.account.current_balance
                        ).toFixed(2),
                        goalAccountId: item.account.id,
                      };

                      localStorage.setItem(
                        "storeProduct",
                        JSON.stringify(productObj)
                      );
                      this.fetchSpendWalletAndGoalsBalance();
                      this.closeSheetTransferFunds();
                    } else if (status.invalidToken(statusCode)) {
                      this.TOAST(
                        "Something went wrong! Please contact our support team."
                      );
                    } else if (status.error(statusCode)) {
                      this.TOAST(
                        "Something went wrong! Please contact our support team."
                      );
                    }
                  }.bind(this)
                )
                .catch(function (error) {
                  this.TOAST(
                    "Something went wrong! Please contact our support team."
                  );
                  throw error;
                });
            } else if (status.invalidToken(statusCode)) {
              this.TOAST(
                "Something went wrong! Please contact our support team."
              );
            } else if (status.error(statusCode)) {
              this.TOAST(
                "Something went wrong! Please contact our support team."
              );
            }
          })
          .catch((error) => {
            this.TOAST(
              "Something went wrong! Please contact our support team."
            );
            throw error;
          });
      },
      transferActionFromWallet() {
        const details = {
          main_account_id: this.selectedTransferAccount,
          goal_account_id: this.selectedGoal.account_id,
          sum: this.transferObj.sum,
          name: "Transfer funds from spend wallet to goal",
          description: "Transfer funds from spend wallet to goal",
        };

        const token = localStorage.getItem("children_token");
        const userId = localStorage.getItem("children_user_id");
        const familyIdChk = localStorage.getItem("children_family_id");
        axios
          .post("/operations/transferFromMainToGoal", {
            userId: userId,
            token: token,
            family_id_check: familyIdChk,
            main_account_id: details.main_account_id,
            goal_account_id: details.goal_account_id,
            name: details.name,
            sum: details.sum,
            description: details.description,
          })
          .then((response) => {
            const { statusCode } = response.data;
            if (status.success(statusCode)) {
              this.$store.commit("TRANSFER_FOUNDS_FROM_MAIN_TO_GOAL", details);

              axios
                .get(
                  "/goalsDashboard/children?" +
                    "userId=" +
                    userId +
                    "&token=" +
                    token +
                    "&family_id_check=" +
                    familyIdChk
                )
                .then(
                  function (response) {
                    const { statusCode } = response.data;

                    if (status.success(statusCode)) {
                      this.$store.commit("FETCH_GOALS", response.data);

                      let item = this.getGoals.find(
                        (item) => item.id === this.selectedGoal.goal_id
                      );
                      // console.log("item: ", item);
                      const productObj = {
                        from: "goals",
                        selectedSection: "details",
                        name: item.product.name,
                        productId: item.product.id,
                        description: item.product.description,
                        price: Number(item.product.price).toFixed(2),
                        stars: Number(item.product.stars),
                        defaultPicture: item.product.default_picture_link,
                        images: this.getProductImages(item.product),
                        cashYouMoreNeed:
                          item.cash_u_need_more != null ||
                          item.cash_u_need_more != undefined
                            ? Number(item.cash_u_need_more)
                            : 0,
                        id: item.id,
                        savingsAdded: Number(
                          item.account.current_balance
                        ).toFixed(2),
                        goalAccountId: item.account.id,
                      };

                      localStorage.setItem(
                        "storeProduct",
                        JSON.stringify(productObj)
                      );
                      this.fetchSpendWalletAndGoalsBalance();
                      this.closeSheetTransferFunds();
                    } else if (status.invalidToken(statusCode)) {
                      this.TOAST(
                        "Something went wrong! Please contact our support team."
                      );
                    } else if (status.error(statusCode)) {
                      this.TOAST(
                        "Something went wrong! Please contact our support team."
                      );
                    }
                  }.bind(this)
                )
                .catch(function (error) {
                  this.TOAST(
                    "Something went wrong! Please contact our support team."
                  );
                  throw error;
                });

              // this.fetchGoals();

              // setTimeout(() => {
              //   console.log(this.getGoals);
              //   var item = this.getGoals.find(
              //     (item) => item.id === this.storeProduct.id
              //   );
              //   console.log("item: ", item);
              //   const productObj = {
              //     from: "goals",
              //     selectedSection: "details",
              //     name: item.product.name,
              //     productId: item.product.id,
              //     description: item.product.description,
              //     price: Number(item.product.price).toFixed(2),
              //     stars: Number(item.product.stars),
              //     defaultPicture: item.product.default_picture_link,
              //     images: this.getProductImages(item.product),
              //     cashYouMoreNeed:
              //       item.cash_u_need_more != null ||
              //       item.cash_u_need_more != undefined
              //         ? Number(item.cash_u_need_more)
              //         : 0,
              //     id: item.id,
              //     savingsAdded: Number(item.account.current_balance).toFixed(2),
              //     goalAccountId: item.account.id,
              //   };

              //   localStorage.setItem("storeProduct", JSON.stringify(productObj));
              //   this.fetchSpendWalletAndGoalsBalance();
              // }, 3000);
              // this.closeSheetTransferFunds();
            } else if (status.invalidToken(statusCode)) {
              this.TOAST(
                "Something went wrong! Please contact our support team."
              );
            } else if (status.error(statusCode)) {
              this.TOAST(
                "Something went wrong! Please contact our support team."
              );
            }
          })
          .catch((error) => {
            this.TOAST(
              "Something went wrong! Please contact our support team."
            );
            throw error;
          });

        // console.log("transfer from goal");
        // this.transferFoundsFromMainToGoal(details);

        // location.reload()
      },
      transferAction() {
        const details = {
          goal_account_id_src: this.selectedTransferAccount,
          goal_account_id_dest: this.selectedGoal.account_id,
          sum: this.transferObj.sum,
          name: "Transfer funds from goal to goal",
          description: "Transfer funds from goal to goal",
        };

        const token = localStorage.getItem("children_token");
        const userId = localStorage.getItem("children_user_id");
        const familyIdChk = localStorage.getItem("children_family_id");
        axios
          .post("/operations/transferFromGoalToGoal", {
            userId: userId,
            token: token,
            family_id_check: familyIdChk,
            goal_account_id_src: details.goal_account_id_src,
            goal_account_id_dest: details.goal_account_id_dest,
            sum: details.sum,
            name: details.name,
            description: details.description,
          })
          .then((response) => {
            const { statusCode } = response.data;
            if (status.success(statusCode)) {
              this.$store.commit("TRANSFER_FOUNDS_FROM_GOAL_TO_GOAL", details);

              axios
                .get(
                  "/goalsDashboard/children?" +
                    "userId=" +
                    userId +
                    "&token=" +
                    token +
                    "&family_id_check=" +
                    familyIdChk
                )
                .then(
                  function (response) {
                    const { statusCode } = response.data;

                    if (status.success(statusCode)) {
                      this.$store.commit("FETCH_GOALS", response.data);

                      let item = this.getGoals.find(
                        (item) => item.id === this.selectedGoal.goal_id
                      );

                      const productObj = {
                        from: "goals",
                        selectedSection: "details",
                        name: item.product.name,
                        productId: item.product.id,
                        description: item.product.description,
                        price: Number(item.product.price).toFixed(2),
                        stars: Number(item.product.stars),
                        defaultPicture: item.product.default_picture_link,
                        images: this.getProductImages(item.product),
                        cashYouMoreNeed:
                          item.cash_u_need_more != null ||
                          item.cash_u_need_more != undefined
                            ? Number(item.cash_u_need_more)
                            : 0,
                        id: item.id,
                        savingsAdded: Number(
                          item.account.current_balance
                        ).toFixed(2),
                        goalAccountId: item.account.id,
                      };

                      localStorage.setItem(
                        "storeProduct",
                        JSON.stringify(productObj)
                      );
                      this.fetchSpendWalletAndGoalsBalance();
                      this.closeSheetTransferFunds();
                    } else if (status.invalidToken(statusCode)) {
                      this.TOAST(
                        "Something went wrong! Please contact our support team."
                      );
                    } else if (status.error(statusCode)) {
                      this.TOAST(
                        "Something went wrong! Please contact our support team."
                      );
                    }
                  }.bind(this)
                )
                .catch(function (error) {
                  this.TOAST(
                    "Something went wrong! Please contact our support team."
                  );
                  throw error;
                });
            } else if (status.invalidToken(statusCode)) {
              this.TOAST(
                "Something went wrong! Please contact our support team."
              );
            } else if (status.error(statusCode)) {
              this.TOAST(
                "Something went wrong! Please contact our support team."
              );
            }
          })
          .catch((error) => {
            this.TOAST(
              "Something went wrong! Please contact our support team."
            );
            throw error;
          });
      },
      stepChanged: function (step) {
        if (step == 1) {
          this.passStep = false;
          this.currentstep = step;
        } else {
          this.passStep = true;
        }

        this.currentstep = step;

        if (step == 2) {
          this.currentstep = step;
          this.passStep = false;
        }
      },
      selectTransfer(id, item, type) {
        if (item.current_balance > 0 || item.account?.current_balance > 0) {
          this.selectedTransferAccount = id;
          this.transferType = type;
          this.passStep = true;
          this.walletType = item.name;

          if (item.account != undefined) {
            this.transferAll = item.account.current_balance;
          } else {
            this.transferAll = item.current_balance;
          }
        }
      },
      selectTransferTo(id, item) {
        this.selectedTransferToAccount = id;
        this.finishTransferTo = item;
        this.transferObj.to = id;
        this.passStep = true;
      },
      resolveImage(src) {
        return config.HOST + src;
      },
      closeSheetTransferFunds() {
        this.sheetTransferFunds = false;
      },
      openSheetTransferFunds(goal_id, account_id, cash_needed, goal_price) {
        this.selectedGoal = {
          goal_id: goal_id,
          account_id: account_id,
          cash_needed: cash_needed,
          goal_price: goal_price,
        };
        this.currentstep = 1;
        this.selectedTransferAccount = null;
        this.transferAll = null;
        this.transferType = null;
        this.passStep = false;
        this.transferObj = {
          from: null,
          to: null,
          sum: null,
        };
        this.fetchGoals(false);
        this.sheetTransferFunds = true;
      },
      progressOffset(radius, percentage) {
        return radius * (1 - percentage);
      },
      getProductImages(product) {
        if (product == null) return [];
        let pictures = [];
        if (product.default_picture_link)
          pictures[0] = product.default_picture_link;
        if (product.picture1_link) pictures[1] = product.picture1_link;
        if (product.picture2_link) pictures[2] = product.picture2_link;
        if (product.picture1_link) pictures[3] = product.picture2_link;
        return pictures;
      },
      skip(wishlist) {
        if (this.checkSlide()) {
          this.restSlide();
        } else {
          const productObj = {
            from: "goals",
            selectedSection: "details",
            name: wishlist.product.name,
            productId: wishlist.product.id,
            description: wishlist.product.description,
            price: Number(wishlist.product.price).toFixed(2),
            stars: Number(wishlist.product.stars),
            defaultPicture: wishlist.product.default_picture_link,
            images: this.getProductImages(wishlist.product),
            cashYouMoreNeed:
              wishlist.cash_u_need_more != null ||
              wishlist.cash_u_need_more != undefined
                ? Number(wishlist.cash_u_need_more)
                : 0,
            id: wishlist.id,
            savingsAdded: Number(wishlist.account.current_balance).toFixed(2),
            goalAccountId: wishlist.account.id,
          };

          localStorage.setItem("storeProduct", JSON.stringify(productObj));
          this.$router.replace({
            name: "children-product_details_goal",
            // params: {
            //   wish: {
            //     selectedSection: "details",
            //     name: wishlist.products.name,
            //     productId: wishlist.products.id,
            //     description: wishlist.products.description,
            //     price: Number(wishlist.products.price).toFixed(2),
            //     stars: Number(wishlist.products.stars),
            //     defaultPicture: wishlist.products.default_picture_link,
            //     images: this.getProductImages(wishlist.products),
            //     cashYouMoreNeed: wishlist.cash_u_need_more,
            //   },
            // },
          });
        }
      },
      showGoalDetails(wishlist) {
        console.log("goal details:", wishlist);
        const productObj = {
          from: "goals",
          selectedSection: "details",
          name: wishlist.product.name,
          productId: wishlist.product.id,
          description: wishlist.product.description,
          price: Number(wishlist.product.price).toFixed(2),
          stars: Number(wishlist.product.stars),
          defaultPicture: wishlist.product.default_picture_link,
          images: this.getProductImages(wishlist.product),
          cashYouMoreNeed:
            wishlist.cash_u_need_more != null ||
            wishlist.cash_u_need_more != undefined
              ? Number(wishlist.cash_u_need_more)
              : 0,
          id: wishlist.id,
          savingsAdded: Number(wishlist.account.current_balance).toFixed(2),
          goalAccountId: wishlist.account.id,
        };

        localStorage.setItem("storeProduct", JSON.stringify(productObj));
        this.$router.replace({
          name: "children-product_details_goal",
        });
      },
      //Start sliding
      touchStart: function (e) {
        // record the initial position
        this.startX = e.touches[0].clientX;
      },
      //End of sliding
      touchEnd: function (e) {
        // The parent element of the current slide
        let parentElement = e.currentTarget.parentElement;
        // record end position
        this.endX = e.changedTouches[0].clientX;
        // slide left
        if (parentElement.dataset.type == 0 && this.startX - this.endX > 30) {
          this.restSlide();
          parentElement.dataset.type = 1;
        }
        // slide right
        if (parentElement.dataset.type == 1 && this.startX - this.endX < -30) {
          this.restSlide();
          parentElement.dataset.type = 0;
        }
        this.startX = 0;
        this.endX = 0;
      },
      //Determine whether there is a slider in the sliding state
      checkSlide() {
        let listItems = document.querySelectorAll(".list-item");
        for (let i = 0; i < listItems.length; i++) {
          if (listItems[i].dataset.type == 1) {
            return true;
          }
        }
        return false;
      },
      //Reset the sliding state
      restSlide() {
        let listItems = document.querySelectorAll(".list-item");
        // reset
        for (let i = 0; i < listItems.length; i++) {
          listItems[i].dataset.type = 0;
        }
      },
      deleteItem(id) {
        swal({
          title: "Are you sure?",
          text: "This goal will be deleted!",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            this.deleteGoal(id);
            this.restSlide();
          }
        });
      },
      testing(goal) {
        const productObj = {
          selectedSection: "details",
          name: goal.products.name,
          productId: goal.products.id,
          description: goal.products.description,
          price: Number(goal.products.price).toFixed(2),
          stars: Number(goal.products.stars),
          defaultPicture: goal.products.default_picture_link,
          images: this.getProductImages(goal.products),
          cashYouMoreNeed: goal.cash_u_need_more,
        };

        // console.log(productObj)

        localStorage.setItem("storeProduct", JSON.stringify(productObj));
      },
      openTranferModal() {
        this.transferModal = true;
      },
      closeTranferModal() {
        this.transferModal = false;
      },
      filterItemsByKey() {
        if (this.search != null && this.search.length > 1) {
          let i;
          this.filtered_goals = [];
          for (i = 0; i < this.goals.length; i++) {
            if (
              this.goals[i].product.name
                .toLowerCase()
                .search(this.search.toLowerCase()) > -1
            ) {
              this.filtered_goals.push(this.goals[i]);
            }
          }
        } else {
          this.filtered_goals = this.goals;
        }
      },
      getGoalImages(product) {
        if (product == null) return [];
        let pictures = [];
        if (product.default_picture_link)
          pictures[0] = product.default_picture_link;
        if (product.picture1_link) pictures[1] = product.picture1_link;
        if (product.picture2_link) pictures[2] = product.picture2_link;
        if (product.picture1_link) pictures[3] = product.picture2_link;
        return pictures;
      },
      getStars(product) {
        if (product.stars == null) return 0;
        return product.stars.stars;
      },
      // getCurrentBalance(account) {
      //   //   console.log(account);
      //   if (account.current_balance == null) return 0;
      //   return account.current_balance;
      // },
      getOcassion(occasion) {
        if (occasion == null) return null;
        else return occasion.image;
      },
    },
    computed: {
      ...mapGetters([
        "takeGoals",
        "getSpendWalletBalance",
        "getSaveWalletBalance",
        "getGoals",
        "user",
      ]),
      checkPassStep() {
        return this.passStep;
      },
      validateFunds() {
        if (
          this.transferObj.sum > 0 &&
          this.transferObj.sum <= this.transferAll
        ) {
          return "blue";
        } else {
          return "";
        }
      },
    },
    created() {
      const user = {
        userId: localStorage.getItem("children_user_id"),
        token: localStorage.getItem("children_token"),
        family_id_check: localStorage.getItem("children_family_id"),
      };
      this.fetchProfile();
      this.setupUser(user);
      this.fetchGoals();
    },
  };
</script>

<style lang="scss" scoped>
  .goals {
    display: grid;
    grid-template-columns: 10fr 3fr;
    height: 100%;

    &__container {
      height: 100%;
      overflow-y: scroll;
      padding: 0 10px;
      overflow-x: hidden;
      display: grid;
      grid-template-rows: 1fr 3fr;

      &__top {
        display: grid;
        grid-template-columns: auto auto;

        &__title {
          padding-top: 40px;
          margin-left: 10px;

          font-size: 40px;
          line-height: 24px;
          color: #7a615a;
        }

        &__search {
          margin-left: 10px;
          margin-right: 10px;
        }
      }

      &__body {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr;
        height: 100%;
        width: 100%;
        margin-top: 40px;

        &__title {
          font-size: 20px;
          line-height: 25px;
          color: #ff4f79;
        }
      }
    }

    &__sidebar {
      height: 100%;
      display: grid;
      grid-template-rows: 1fr;
      grid-gap: 20px;
      // margin-left: 10px;
      padding: 10px;

      &__wallet {
        height: auto;
      }
    }

    &__input {
      width: 100%;
      height: 70px;
      margin-top: 20px;
      color: #7a615a;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 74px;
      outline: none;
      padding: 0px 30px;
      font: {
        size: 18px;
      }
    }
  }

  .no-goals-container {
    background: #f8f5ed;
    padding: 20px;
    width: 100%;
    margin: 0 10px;
    border-radius: 50px;
    font-weight: 200;
    text-align: center;
  }

  @media screen and (max-width: 800px) {
    .goals__container__body__item__header {
      margin-top: -40px;
    }
  }

  @media screen and (max-width: 880px) {
    .goals__container__body {
      grid-template-columns: 1fr;
    }
  }

  @media screen and (max-width: 800px) {
    .goals {
      display: block;
      grid-template-columns: 1fr;

      &__container {
        height: auto;
        overflow-y: hidden;
      }
    }
    .goals__container__body__title {
      margin: auto;
    }
    .goals__container__top {
      grid-template-columns: 1fr;

      &__title {
        margin: auto;
      }
      &__search {
        margin-bottom: 10px;
      }
    }
    .goalsItem {
      margin-top: 10px;
      border: 2px dashed #ddd;
      border-radius: 20px;
    }
    .goals__container__body {
      margin-top: -40px;
    }
    .goals__bottom {
      grid-template-columns: 1fr;
    }
    .goals__center__missions__container {
      grid-auto-flow: row;
    }
    .goals__sidebar {
      margin-top: 0;
    }
  }

  .goalsHolder {
    background: #f8f5ed;
    // width: 100%;
    border-radius: 40px;
  }

  .goals__sidebar__wallet .spendWallet__price {
    line-height: inherit !important;
  }

  .goals_cont {
    margin-top: 70px;
  }

  .goalItem {
    margin-bottom: 30px;
  }

  .goalItem:first-child {
    margin-top: 0;
  }

  .goals-subtitle {
    display: none;
  }

  .goalDetails__left__images__small {
    height: 80px !important;
    object-fit: contain !important;
  }

  .goalDetails__left__image {
    height: 300px;
    object-fit: contain;
  }

  .goalDetails__right__buttons button {
    font-size: 16px;
  }

  .goalDetails__right__ocassions__icon {
    border: none;
    padding: 0;
    object-fit: contain;
  }

  @media only screen and (max-width: 820px) {
    .goalDetails__left__images__small {
      height: 60px !important;
      object-fit: cover !important;
    }
    .goals-subtitle {
      display: block;
      font-size: 12px;
    }
    .goalsItem {
      border: 5px solid #f9f7f7;
    }
    .goalItem {
      margin-top: 0;
    }
    .goalsItem__header__trash {
      top: 9px !important;
    }
    .goals__container__top {
      display: none;
    }
    .goalsItem__header {
      margin: 0;
      .appGoalsProgressBar__total,
      .wishlist_progress,
      .appGoalsProgressBar,
      .appGoalsProgressBar__total__completed {
        border-bottom-right-radius: 20px;
      }
    }
    .goals__sidebar {
      grid-gap: 10px;
    }

    .goalDetails__left__images {
      padding: 0 !important;
    }
    .appGoalsProgressBar {
      width: 60%;
    }
    .goalDetails__right__title {
      padding: 0 !important;
      margin-top: 30px;
    }
    .goalDetails__right__info {
      padding: 0 !important;
      margin-top: 8px;
    }
    .goalDetails__right__miniGrid__price {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 0 !important;
    }
    .goalDetails__right__miniGrid__savingsAdded {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      flex-direction: column;
      padding-right: 0 !important;
    }
    .goalDetails__right__stars {
      margin-top: 20px;
    }
    .goalDetails__right__miniGrid {
      margin-top: 20px;
    }
    .appButton {
      width: 100% !important;
    }
    .goalDetails__right__buttons button {
      font-size: 16px;
    }
    .vue-star-rating[data-v-34cbeed1],
    .goalDetails__right__stars {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .goalsItem__header__trash {
      width: 30px;
      height: 30px;
      background-size: 15px;
      top: 28px;
      left: -9px;
    }
    .goals__container__top__title {
      text-align: center;
      font-size: 20px;
      padding: 30px 0 0 0;
      margin-bottom: 10px;
    }
    .goals__container {
      display: block;
      padding-top: 80px;
      overflow: hidden;
    }
    .goalDetails {
      height: 100%;
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;
    }
    .goals__container__body {
      margin-top: 0;
    }
    .appContent {
      overflow: hidden;
    }
    .no-goals-container {
      background: #f8f5ed;
      padding: 20px;
      margin: 0 10px;
      border-radius: 10px;
      text-align: center;
      width: 100%;
      .goals__container__top {
        display: none;
      }
    }
    .goals_cont {
      margin: 0 10px;
    }
    .goals__container__body__title {
      color: #7a615a;
      font-size: 16px;
    }
    .goalItem {
      margin-bottom: 10px;
    }
  }

  .list_ul {
    list-style: none;
    // overflow: hidden;
  }

  .list-item {
    position: relative;
    // height: 5rem;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    margin-bottom: 8px;
    border-radius: 15px;
  }

  .list-item[data-type="0"] {
    transform: translate3d(0, 0, 0);
  }

  .list-item[data-type="1"] {
    transform: translate3d(-5rem, 0, 0);
  }

  .list-box {
    padding: 0.5rem;
    background: #fff;
    border-radius: 15px;
    height: 100%;
    display: flex;
    align-items: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    justify-content: flex-end;
  }

  .list-item .list-img {
    display: block;
    width: 2.5rem;
    height: 2.5rem;
  }

  .list-item .list-content {
    padding: 0.25rem 0 0.25rem 0.5rem;
    position: relative;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
  }

  .list-item .title {
    display: block;
    overflow: hidden;
    font-size: 0.75rem;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 200px;
  }

  .list-item .tips {
    display: block;
    overflow: hidden;
    font-size: 0.6rem;
    color: #999;
    line-height: 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 250px;
  }

  .list-item .time {
    display: block;
    font-size: 0.6rem;
    position: absolute;
    right: 0;
    top: 0.25rem;
    color: #666;
  }

  .list-item .delete {
    width: 5rem;
    height: 100%;
    // background: #ff4949;
    font-size: 0.85rem;
    color: var(--main);
    text-align: center;
    position: absolute;
    top: 0;
    right: -5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .recent_container {
    margin-top: 20px;
  }

  .activity_item {
    width: 100%;
    height: 90px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    background: white;
    padding: 15px;
    border-radius: 5px;
    border-bottom-right-radius: 15px;
    position: relative;
    overflow: hidden;
    transition: all 200ms ease-in-out !important;

    &:active {
      opacity: 0.6;
    }

    &__container {
      display: flex;
      align-items: center;
    }

    &__title {
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 0.01rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 110px;
    }

    &__description {
      font-size: 11px;
      color: #9b9b9b;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 185px;
    }

    &__amount {
      position: absolute;
      top: 10px;
      right: 15px;
      color: var(--main);
      font-weight: 600;
      font-size: 11px;
    }

    &__icon {
      margin-right: 15px;
      display: flex;
      align-items: center;

      img {
        width: 50px;
      }
    }
  }

  .guardian-login-form {
    height: 500px;
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;

    &__input {
      background-color: #f8f8f8;
      width: 80%;
      padding: 15px;
      border: 1px solid #e0e0e0;
      border-radius: 10px;
      outline: none;
      font-size: 16px;
      color: #444 !important;
    }
  }

  .transferInfoTitle {
    width: 100%;
    text-align: center;
    color: #7a615a;
    font-weight: 700;
    margin-bottom: 40px;
    font-size: 18px;
  }

  .amountToTransfer {
    // text-align: center;
    color: #7a615a;
    text-align: center;
    border-top: 1px solid #eee;
    padding: 15px 30px;
    width: auto;
    display: table;
    margin: 15px auto 0 auto;
    align-items: center;
    flex-direction: column;

    span {
      color: #36d5f2;
      font-weight: bold;
    }

    .transferAllAction {
      // background: #36d5f2;
      color: var(--main);
      padding: 5px 0;
      // border-radius: 100px;
      font-size: 16px;
      width: auto;
      display: inline-block;
      margin-top: 15px;
      cursor: pointer;
      font-weight: bold;
      border-bottom: 1px dashed var(--main);
      // box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      //   0 4px 6px -2px rgba(0, 0, 0, 0.05), 0 0 10px rgba(black, 0.1) inset;
    }
  }
</style>
