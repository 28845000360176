<template>
  <div class="goal-item goal-item-extra">
    <div
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      "
    >
      <div
        class="goal-img relative"
        style="display: flex; align-items: center; justify-content: center"
        @click="$emit('triggerGoalItem')"
      >
        <div class="relative mt-[-28px]">
          <svg
            class="progress"
            viewBox="0 0 168 116"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              class="progress__container"
              d="M9 112C5.7435 103.285 4 93.8506 4 84C4 39.8172 39.8172 4 84 4C128.183 4 164 39.8172 164 84C164 93.8506 162.256 103.285 159 112"
              stroke="#DBDBDB"
              stroke-width="5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              class="progress__bar"
              :data-radius="progress"
              stroke-dasharray="308 400"
              :stroke-dashoffset="
                progressOffset(
                  progress,
                  (
                    (100 * formatPrice(goal.account.current_balance)) /
                    formatPrice(goal.product.price)
                  ).toFixed() / 100
                )
              "
              d="M9 112C5.7435 103.285 4 93.8506 4 84C4 39.8172 39.8172 4 84 4C128.183 4 164 39.8172 164 84C164 93.8506 162.256 103.285 159 112"
              stroke="#58C1E1"
              stroke-width="5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <img
            class="absolute top-[20px] bottom-0 mx-auto left-0 right-0"
            style="width: 45px; height: 45px; object-fit: contain"
            :src="resolveImage(goal.product.default_picture_link)" alt=""

          >
          <small
            class="
              absolute
              bottom-[-25px]
              left-0
              right-0
              w-[85%]
              mx-auto
              text-center
              border-2 border-[#E9E9E9]
              rounded-lg
              py-[4px]
              px-3
              bg-white
              font-bold
            "
            style="
              box-shadow: 0 5px 0 #e9e9e9;
              color: #58c1e1 !important;
              font-size: 10px;
            "
          >&pound;{{ formatPrice(goal.account.current_balance)
          }}<span style="color: #222">/</span><span
            style="color: var(--secondary)"
          >&pound;{{ formatPrice(goal.product.price) }}</span></small>
        </div>
      </div>
      <div class="font-bold text-right text-xs w-[50%] leading-tight">
        <div
          style="
            display: -webkit-box;
            max-width: 200px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          "
          @click="$emit('triggerGoalItem')"
        >
          {{ goal.product.name }}
        </div>

        <div class="flex items-center justify-end mt-4">

          <div @click="$emit('addGoalFunds')" class="flex flex-col text-center items-center justify-center mr-5">
            <img class="w-8 h-8" src="../assets/images/icon-add-funds.svg" alt="Add Funds">
            <p class="text-[8px] mt-1 uppercase" style="color: var(--main)">Add Funds</p>
          </div>

          <div @click="$emit('deleteGoalItem')" class="flex flex-col text-center items-center justify-center">
            <img class="w-8 h-8" src="../assets/images/icon-delete-goal.svg" alt="Delete Goal">
            <p class="text-[8px] mt-1 uppercase" style="color: var(--red)">Remove Goal</p>
          </div>

          <!-- <small
            style="font-family: var(--fredoka) !important; font-weight: 400"
            >&pound;{{ formatPrice(goal.product.price) }}</small
          > -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import config from "../config";

  export default {
    props: ["goal", "progress"],
    methods: {
      progressOffset(radius, percentage) {
        return radius * (1 - percentage);
      },
      resolveImage(src) {
        return config.HOST + src;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .goal-item-extra {
    flex-direction: column;
    align-items: flex-start;
    padding: 25px;
    border-radius: 8px;
    margin-bottom: 15px;
    border: 1px solid #dedede;
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.1);
  }
</style>